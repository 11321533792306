import LinkIcon from '@/assets/icons/link.svg?react';

import { useContext } from 'react';
import { Button } from '../ui/button';
import { DialogContent } from '../ui/dialog';
import { Input } from '../ui/input';
import { Separator } from '../ui/separator';

import { ApiException } from '@/lib/exceptions';
import { cn } from '@/lib/utils';
import { trackLogUploadAudioInteraction } from '@/services/analytics-service';
import { useCreateAudioConversion } from '@/services/audio-service';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Resolver } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';
import Spinner from '../misc/spinner';
import { DialogCloseContext } from '../ui/dialog-dropdown';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';

const formSchema = z.object({
  url: z.string().url(),
});
type FormValues = z.infer<typeof formSchema>;

export function LinkWebDialog({
  closeDialogFn,
}: {
  closeDialogFn?: () => void;
}) {
  const closeContext = useContext(DialogCloseContext);
  const closeDialog = closeDialogFn ?? closeContext.closeDialog;

  const urlMutation = useCreateAudioConversion({ useV2Api: false });
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema) as Resolver<FormValues>,
    defaultValues: {
      url: '',
    },
  });

  return (
    <DialogContent className="max-h-[90%] rounded-2xl">
      <Form {...form}>
        <form
          className="flex size-full flex-col items-center rounded-2xl"
          onSubmit={form.handleSubmit((values: z.infer<typeof formSchema>) => {
            const url = values.url;
            if (!url) return;
            trackLogUploadAudioInteraction({
              interaction: 'link_submitted',
              location: 'link_dialog',
              url,
            });
            urlMutation.mutate(url, {
              onSuccess: () => {
                toast.success(`Added ${url} to your library`);
                closeDialog();
              },
              onError: (e) => {
                toast.error(
                  ApiException.getErrorMessage(
                    e,
                    'There was an error while requesting conversion',
                  ),
                );
              },
              onSettled: () => {
                urlMutation.reset();
              },
            });
          })}
        >
          <div className="pb-4">
            <LinkWebDialog.Icon />
          </div>
          <h3 className="text-lg font-[700]">Link to web page</h3>
          <p>Paste the URL of a website or pdf</p>

          <FormField
            control={form.control}
            name="url"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>URL</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="text"
                    className="w-full text-foreground"
                    placeholder="www.sitename.com"
                    onPaste={(e) => {
                      trackLogUploadAudioInteraction({
                        interaction: 'link_pasted',
                        location: 'link_dialog',
                        url: e.clipboardData.getData('text'),
                      });
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Separator className="my-4" />
          <Button
            className="flex w-full gap-1 rounded-full"
            type="submit"
            disabled={urlMutation.isPending}
          >
            {urlMutation.isPending && <Spinner />}
            Convert to audio
          </Button>
        </form>
      </Form>
    </DialogContent>
  );
}

LinkWebDialog.Icon = ({ className }: { className?: string }) => (
  <div className="rounded-full bg-[#1570EF0D] p-[4.5px] dark:bg-[#FFFFFF0D]">
    <div className="rounded-full bg-[#1570EF0D] p-3 dark:bg-[#FFFFFF0D]">
      <LinkIcon className={cn('size-8 text-[#1570EF]', className)} />
    </div>
  </div>
);
