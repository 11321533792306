import { flatChildren } from '@/lib/audio-utils';
import type { QueryClient } from '@tanstack/react-query';
import { queryOptions } from '@tanstack/react-query';
import {
  getAudioItemReq,
  getChapterDetailsReq,
  getProcessingQueueReq,
  listAudioItemsReq,
} from './api-service';
import { validTokenGuard } from './user-service';

export function listAudioQueryOpts(token: string | undefined) {
  return queryOptions({
    enabled: !!token,
    queryKey: [listAudioItemsReq.name, token],
    queryFn: () => listAudioItemsReq(validTokenGuard(token)),
    refetchInterval: 25000,
  });
}

export function listProcessingQueueQueryOpts(token: string | undefined) {
  return queryOptions({
    enabled: !!token,
    queryKey: [getProcessingQueueReq.name, token],
    queryFn: () => getProcessingQueueReq(validTokenGuard(token)),
    refetchInterval: 25000,
  });
}

export function fetchChapterDetailsQueryOpts(opts: {
  token: string | undefined;
  audioId?: string;
  chapterId?: string;
}) {
  return queryOptions({
    gcTime: Infinity,
    staleTime: Infinity,
    enabled: !!opts.audioId && !!opts.chapterId,

    queryKey: [
      getChapterDetailsReq.name,
      opts.token,
      opts.audioId,
      opts.chapterId,
    ],
    queryFn: () => {
      if (!opts.audioId || !opts.chapterId)
        throw new Error('audioConversionId and chapterId must be provided');
      return getChapterDetailsReq(
        validTokenGuard(opts.token),
        opts.audioId,
        opts.chapterId,
      );
    },
  });
}

export function fetchAudioDetailQueryOpts(opts: {
  token: string | undefined;
  queryClient: QueryClient;
  id?: string;
}) {
  return queryOptions({
    queryKey: [getAudioItemReq.name, opts.token, opts.id],
    queryFn: () => {
      if (!opts.id) return;
      const listData = opts.queryClient.getQueryData(
        listAudioQueryOpts(opts.token).queryKey,
      );
      const audioItem = (listData?.data ?? []).find(
        (item) => item.audioConversionID === opts.id,
      );
      if (audioItem) return audioItem;
      return getAudioItemReq(validTokenGuard(opts.token), opts.id);
    },
    staleTime: 1000 * 60 * 2,
  });
}

export function fetchChapterMetadataOpts(opts: {
  token: string | undefined;
  queryClient: QueryClient;
  chapter?: {
    chapterId: string;
    audioId: string;
  };
}) {
  return queryOptions({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps -- this is weird
    queryKey: [
      'chapter-metadata',
      opts.chapter,
      opts.queryClient,
      opts.token,
      opts.chapter?.audioId,
    ],
    queryFn: async () => {
      if (!opts.chapter) return;
      const audioFetchOpts = fetchAudioDetailQueryOpts({
        queryClient: opts.queryClient,
        token: opts.token,
        id: opts.chapter.audioId,
      });
      const audioItem = await opts.queryClient.fetchQuery({
        queryKey: audioFetchOpts.queryKey,
        queryFn: audioFetchOpts.queryFn,
      });
      if (!audioItem) return;
      const chapters = flatChildren(audioItem.audioConversion.chapters);
      return chapters.find(
        (chapter) => chapter.chapter_id === opts.chapter?.chapterId,
      );
    },
  });
}
