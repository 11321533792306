import {
  useAudioItemQuery,
  useChapterMetadataQuery,
} from '@/services/audio-service';
import type {} from '@redux-devtools/extension'; // required for devtools typing
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type AudioStoreState = {
  audioItemId?: string;
  chapterId?: string;
  setAudioItem: (audioItemId?: string, chapterId?: string) => void;
  setChapter: (chapterId: string) => void;
  setShuffle: (shuffle: boolean) => void;
  shuffle: boolean;
};

export const useAudioStore = create<AudioStoreState>()(
  devtools((set) => ({
    audioItemId: undefined,
    chapterId: undefined,
    setAudioItem: (audioItemId?: string, chapterId?: string) => {
      if (!audioItemId) {
        set({ audioItemId: undefined, chapterId: undefined });
        return;
      }
      set({ audioItemId, chapterId });
    },
    setChapter: (chapterId: string) => {
      set({ chapterId });
    },
    setShuffle: (shuffle: boolean) => {
      set({ shuffle });
    },
    shuffle: false,
  })),
);

export const useCurrentAudioItem = () => {
  const audioItemId = useAudioStore((state) => state.audioItemId);
  const query = useAudioItemQuery(audioItemId);

  return { query, audioItemId };
};

export const useCurrentChapterMetadata = () => {
  const chapterId = useAudioStore((state) => state.chapterId);
  const audioItemId = useAudioStore((state) => state.audioItemId);
  const query = useChapterMetadataQuery(
    chapterId && audioItemId ? { chapterId, audioId: audioItemId } : undefined,
  );

  return { query, chapterId };
};

export const fontStyleLabelToClassName: Record<
  (typeof fontStyles)[number],
  string
> = {
  Poppins: "font-['Poppins']",
  Besley: "font-['Besley_Variable']",
  'Roboto Serif': "font-['Roboto_Serif_Variable']",
  'Playfair Display': "font-['Playfair_Display_Variable']",
  Quicksand: "font-['Quicksand_Variable']",
};

export const fontStyles = [
  'Poppins',
  'Besley',
  'Roboto Serif',
  'Playfair Display',
  'Quicksand',
] as const;

export const fontSizes = ['Small', 'Medium', 'Large'] as const;

type FontSettings = {
  size: (typeof fontSizes)[number];
  style: (typeof fontStyles)[number];
};

type LocalPrefsState = {
  setPlaybackRate: (playbackRate: number) => void;
  playbackRate: number;
  setVolume: (volume: number) => void;
  volume: number;
  setFontSettings: (settings: FontSettings) => void;
  fontSettings: FontSettings;
  reset: () => void;
  readAlongViewer: 'normal' | 'pdf';
  setReadAlongViewer: (viewer: 'normal' | 'pdf') => void;
  pdfScale: number;
  setPdfScale: (scale: number) => void;
  autoScroll: boolean;
  setAutoScroll: (autoScroll: boolean) => void;
};

export const useLocalPrefsStore = create<LocalPrefsState>()(
  persist(
    (set) => ({
      setPlaybackRate: (playbackRate: number) => {
        set({ playbackRate });
      },
      playbackRate: 1,
      setVolume: (volume: number) => {
        set({ volume });
      },
      volume: 1,
      setFontSettings: (settings: FontSettings) => {
        set({ fontSettings: settings });
      },
      fontSettings: {
        size: 'Medium',
        style: 'Poppins',
      },
      reset: () => {
        set({
          playbackRate: 1,
          volume: 1,
          fontSettings: {
            size: 'Medium',
            style: 'Poppins',
          },
        });
      },
      readAlongViewer: 'pdf',
      setReadAlongViewer: (viewer: 'normal' | 'pdf') => {
        set({ readAlongViewer: viewer });
      },
      pdfScale: 1,
      setPdfScale: (scale: number) => {
        set({ pdfScale: scale });
      },
      autoScroll: false,
      setAutoScroll: (autoScroll: boolean) => {
        set({ autoScroll });
      },
    }),
    {
      name: 'audio-prefs-storage',
    },
  ),
);
