import type {
  AudioConversionDTO,
  AudioItemDTO,
  AudioItemMetaData,
  TagDTO,
} from '@/lib/audio-utils';
import { flatChildren, useAudioTimeSec } from '@/lib/audio-utils';
import { logger } from '@/lib/logger';
import {
  useCurrentAudioItem,
  useCurrentChapterMetadata,
} from '@/stores/audio-store';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import {
  convertUrlAudioReq,
  deleteAudioItemReq,
  listAudioItemsReq,
  safeApiClient,
  updateAudioItemReq,
} from './api-service';
import {
  fetchAudioDetailQueryOpts,
  fetchChapterDetailsQueryOpts,
  fetchChapterMetadataOpts,
  listAudioQueryOpts,
  listProcessingQueueQueryOpts,
} from './query-service';
import { useUserToken, validTokenGuard } from './user-service';

export const sessionConversionHistoryAtom = atomWithStorage<
  (AudioConversionDTO | AudioItemMetaData)[]
>('sessionConversionHistoryAtom', []);

export const useListAudioQuery = () => {
  const { token } = useUserToken();
  return useQuery(listAudioQueryOpts(token));
};

export const useProcessingQueueQuery = () => {
  const { token } = useUserToken();
  return useQuery(listProcessingQueueQueryOpts(token));
};

export const useAudioItemQuery = (id?: string) => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  return useQuery(
    fetchAudioDetailQueryOpts({
      id,
      token,
      queryClient,
    }),
  );
};

export const useChapterMetadataQuery = (opts?: {
  chapterId: string;
  audioId: string;
}) => {
  const queryClient = useQueryClient();
  const { token } = useUserToken();
  return useQuery(
    fetchChapterMetadataOpts({
      queryClient,
      token,
      chapter: opts,
    }),
  );
};

export const useFetchAllChapterDetails = ({
  audioItem,
}: {
  audioItem: AudioItemDTO;
}) => {
  const flatChildren = useFlatChapters(audioItem);
  const { token } = useUserToken();

  return useQueries({
    queries: flatChildren.map((chapter) =>
      fetchChapterDetailsQueryOpts({
        audioId: audioItem.audioConversionID,
        chapterId: chapter.chapter_id,
        token,
      }),
    ),
  });
};

export const useChapterDetailsQuery = ({
  audioConversionId,
  chapterId,
}: {
  audioConversionId?: string;
  chapterId?: string;
}) => {
  const { token } = useUserToken();

  return useQuery(
    fetchChapterDetailsQueryOpts({
      token,
      audioId: audioConversionId,
      chapterId,
    }),
  );
};

export const useCurrentAudioChapterDetailsQuery = () => {
  const chapter = useCurrentChapterMetadata().query.data;
  const audioItem = useCurrentAudioItem().query.data;

  const audioConversionId = audioItem?.audioConversionID;
  const chapterId = chapter?.chapter_id;

  return {
    getChapterDetailsQuery: useChapterDetailsQuery({
      audioConversionId,
      chapterId,
    }),
    chapter,
    audioItem,
  };
};

export const isTimestampActive = (
  currentTime: number,
  ts: { start_ms: number; end_ms: number },
) => {
  const currentMs = currentTime * 1000;
  return currentMs >= ts.start_ms && currentMs < ts.end_ms;
};

export const useIsLabelActive = () => {
  const chapter = useCurrentChapterMetadata().query.data;

  return useCallback(
    (
      currentTime: number,
      chapterId: string,
      ts: { start_ms: number; end_ms: number },
    ) => {
      return (
        chapter &&
        chapter.chapter_id === chapterId &&
        isTimestampActive(currentTime, ts)
      );
    },
    [chapter],
  );
};

export const useFlatChapters = (audioItem: AudioItemDTO) =>
  useMemo(
    () => flatChildren(audioItem.audioConversion.chapters),
    [audioItem.audioConversion.chapters],
  );

export const useFlatChaptersEmpty = (audioItem?: AudioItemDTO | null) =>
  useMemo(
    () => (audioItem ? flatChildren(audioItem.audioConversion.chapters) : []),
    [audioItem],
  );

export const useActiveText = (level: 'sentence' | 'paragraph') => {
  const { getChapterDetailsQuery } = useCurrentAudioChapterDetailsQuery();
  const time = useAudioTimeSec();

  if (!getChapterDetailsQuery.data) return null;

  const activeParagraph =
    getChapterDetailsQuery.data.timestamps.paragraph_timestamps.find((p) =>
      isTimestampActive(time, p),
    );
  if (level === 'sentence') {
    const activeSentence = activeParagraph?.sentence_timestamps.find((s) =>
      isTimestampActive(time, s),
    );
    const activeSentenceText =
      activeSentence?.word_timestamps.map((w) => w.text).join(' ') ?? null;
    return activeSentenceText;
  }
  const activeParagraphText =
    activeParagraph?.sentence_timestamps
      .map((s) => s.word_timestamps.map((w) => w.text).join(' '))
      .join(' ') ?? null;
  return activeParagraphText;
};

export const useCreateAudioConversion = ({
  useV2Api,
}: {
  useV2Api: boolean;
}) => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  const [, setHistory] = useAtom(sessionConversionHistoryAtom);

  return useMutation({
    mutationFn: async (url: string) => {
      if (!useV2Api) return convertUrlAudioReq(url, validTokenGuard(token));
      const response = await safeApiClient.POST('/v2/audio', {
        body: {
          document_url: url,
        },
      });
      if (!response.data?.audio_conversion)
        throw new Error('No audio conversion data');
      return response.data.audio_conversion;
    },
    onSuccess: async (audioConversion, url) => {
      logger.log(`Successfully requested converted url`, url);
      setHistory((history) => [...history, audioConversion]);
      await queryClient.invalidateQueries(listAudioQueryOpts(token));
      await queryClient.refetchQueries(listProcessingQueueQueryOpts(token));
    },
    onError: (error) => {
      logger.error(error);
    },
    networkMode: 'always',
  });
};

export const useDeleteAudioMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (audioItemId: string) =>
      deleteAudioItemReq(validTokenGuard(token), audioItemId),
    onSuccess: (_, audioItemId) => {
      logger.log('Deleted audio item successfully');
      queryClient.setQueriesData(
        { queryKey: listAudioQueryOpts(token).queryKey },
        (old: { data: AudioItemDTO[] } | undefined) =>
          old != undefined
            ? {
                ...old,
                data: old.data.filter(
                  (item) => item.audioConversionID != audioItemId,
                ),
              }
            : undefined,
      );
      return queryClient.invalidateQueries(listAudioQueryOpts(token));
    },
  });
};

export const useUpdateAudioMutation = () => {
  const { token } = useUserToken();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      id,
      newTags,
      newTitle,
    }: {
      id: string;
      newTags?: TagDTO[];
      newTitle?: string;
    }) => updateAudioItemReq(validTokenGuard(token), id, newTags, newTitle),
    onSuccess: (_, { id, newTags, newTitle }) => {
      logger.log('Updated audio item successfully');

      queryClient.setQueriesData(
        { queryKey: listAudioQueryOpts(token).queryKey },
        (old: { data: AudioItemDTO[] } | undefined) =>
          old != undefined
            ? {
                ...old,
                data: old.data.map((item) =>
                  item.audioConversionID == id
                    ? {
                        ...item,
                        tags: newTags ? [...newTags] : item.tags,
                        title: newTitle ?? item.title,
                      }
                    : item,
                ),
              }
            : undefined,
      );
      return queryClient.invalidateQueries({
        queryKey: [listAudioItemsReq.name],
      });
    },
  });
};
